import LocationCard from '@/components/cards/LocationCardSearch';
import { CoworkingDayPassPricesDto } from '@/lib/coworking-day-pass/dto/coworking-day-pass-price.dto';
import { SimpleLocationSearchDto } from '@/lib/locations/dto/simple-location-search.dto';
import { DateRange } from '@/models/DateRange';

type LocationSearchListProps = {
  locations: SimpleLocationSearchDto[];
  prices?: CoworkingDayPassPricesDto;
  dateRange?: DateRange;
  cardHeight?: number;
  onMouseEnter: (slug: string) => void;
  onMouseLeave: () => void;
};

export default function LocationSearchList({
  locations,
  prices,
  dateRange,
  cardHeight,
  onMouseEnter,
  onMouseLeave,
}: LocationSearchListProps) {
  return (
    <ul className="flex flex-col gap-5">
      {locations.map((loc, index) => (
        <li key={loc.slug} data-testid={`location-list-${index}`}>
          <LocationCard
            priority={index === 0}
            location={loc}
            prices={prices?.[loc.id]}
            dateRange={dateRange}
            cardHeight={cardHeight}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </li>
      ))}
    </ul>
  );
}
